import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import tachyons from 'styled-components-tachyons'

import { Caret } from '../Icon'
import { CaretAlt } from '../Icon'

const CarouselBase = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  ${tachyons};
`

const CarouselInner = styled.div`
  display: flex;
  width: ${props => (100 / props.mobilePerPage) * props.slides.length}%;
  margin-left: ${props => props.index * (-100 / props.mobilePerPage)}%;
  transition: margin 600ms;

  @media (min-width: 600px) {
    width: ${props => (100 / props.perPage) * props.slides.length}%;
    margin-left: ${props => props.index * -(100 / props.perPage)}%;
  }

  ${tachyons};
`

const CarouselContents = styled.div`
  width: calc(100% - 50px);
  margin-left: 25px;
}
`

const CarouselFrame = styled.div`
  text-align: center;
  width: ${props => 100 / props.perPage}%;
`

const CarouselIcon = styled.button`
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  background-color: #fff !important;
  border: 0;
  text-indent: -9999px;
  width: 0px;
  z-index: 9;
  outline: none !important;
  cursor: pointer;

  ${props =>
    props.opacity === 0 &&
    css`
      cursor: auto;
    `};

  ${props =>
    props.direction === 'right'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `};

  &:after {
    display: block;
    position: absolute;
    top: 50%;
    width: 21px;
    height: 34px;
    margin-top: -16px;
    content: ' ';
    background-image: url(${Caret});
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      background-color: #2f2f2f;
      border-radius: 50%;
      width: 46px;
      height: 46px;
      background-position: center center;
      background-size: 30%;
      background-image: url(${CaretAlt});
    }

    ${props =>
      props.direction === 'left' &&
      css`
        left: 0;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      `};
    ${props =>
      props.direction !== 'left' &&
      css`
        right: 0;
      `};
    ${props =>
      css`
        opacity: ${props.opacity};
      `};
    transition: opacity 500ms;
  }

  @media (min-width: 600px) {
    width: 42px;
  }

  ${tachyons};
`

class Carousel extends Component {
  state = {
    index: 0,
  }

  isFirst() {
    const { index } = this.state

    return index === 0
  }
  isLast() {
    const { index } = this.state
    const { slides, perPage } = this.props

    return index === slides.length - perPage
  }

  previousButtonClick() {
    if (!this.isFirst()) this.setState({ index: this.state.index - 1 })
  }
  nextButtonClick() {
    if (!this.isLast()) this.setState({ index: this.state.index + 1 })
  }
  constructPreviousButton() {
    const opacity = this.isFirst() ? '0' : '1'
    return (
      <CarouselIcon
        onClick={this.previousButtonClick.bind(this)}
        direction="left"
        opacity={opacity}
      >
        Previous
      </CarouselIcon>
    )
  }
  constructNextButton() {
    const opacity = this.isLast() ? '0' : '1'
    return (
      <CarouselIcon
        onClick={this.nextButtonClick.bind(this)}
        direction="right"
        opacity={opacity}
      >
        Next
      </CarouselIcon>
    )
  }
  constructSlides() {
    const { slides, ...props } = this.props
    const { index } = this.state

    return slides.map((slide, i) => {
      return (
        <CarouselFrame key={i} index={index} {...props}>
          {slide}
        </CarouselFrame>
      )
    })
  }
  render() {
    const { props } = this
    const { index } = this.state

    return (
      <CarouselBase {...props}>
        {this.constructPreviousButton()}
        <CarouselContents>
          <CarouselInner index={index} {...props} {...props.innerProps}>
            {this.constructSlides()}
          </CarouselInner>
        </CarouselContents>
        {this.constructNextButton()}
      </CarouselBase>
    )
  }
}

Carousel.defaultProps = {
  perPage: 1,
  mobilePerPage: 1,
}

export default Carousel
