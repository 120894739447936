import React, { Component } from 'react'

function isRetina() {
  return window.devicePixelRatio && window.devicePixelRatio >= 2
}

class Image extends Component {
  constructor(props) {
    super(props)

    this.state = {
      original: props.src,
      retina: props.retina,
    }
  }
  chooseSrc() {
    return isRetina() ? this.state.retina : this.state.original
  }
  render() {
    const src = this.chooseSrc()

    const { id, class: className, alt, height, width } = this.props
    const imgProps = { id, className, alt, height, width }
    // eslint-disable-next-line
    return <img {...imgProps} src={src} />
  }
}
// TODO: If no retina, default to normal.
export default Image
