import React from 'react'
import styled, { css } from 'styled-components'
import tachyons from 'styled-components-tachyons'

const Badge = styled.div`
  color: ${props => props.color};

  ${props =>
    props.image === 'small' &&
    css`
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        width: 70px;
        height: auto;
        margin-bottom: 10px;
      }

      span {
        max-width: 60%;
      }

      @media only screen and (min-width: 500px) {
        flex-direction: row;

        img {
          margin-bottom: 0;
          margin-right: 20px;
          flex: none;
        }

        span {
          max-width: 100%;
        }
      }
    `} ${tachyons};
`

const FeatureBadge = props => (
  <Badge f5 lh_copy fw6 tracked_tight {...props}>
    {props.children}
  </Badge>
)

export default FeatureBadge
