import styled, { css } from 'styled-components'
import { color } from '../../global/constants'

// const Box = styled.div`
//   ${tachyons};
// `

const Seperated = styled.div`
  position: relative;

  &:before {
    display: block;
    content: ' ';
    width: 100px;
    border-top: 8px solid ${props => (props.white ? 'white' : color.grayDark)};
  }

  ${props =>
    props.tc &&
    css`
      overflow: auto;

      &:before {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -50px;
      }
    `};

  ${props =>
    props.tc_on_mobile &&
    css`
      @media (max-width: 599px) {
        overflow: auto;

        &:before {
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -50px;
        }
      }
    `};
`
export { Seperated }
