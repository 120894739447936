import React from 'react'
import LazyLoad from 'react-lazyload'
import styled, { css } from 'styled-components'
import tachyons from 'styled-components-tachyons'

import Image from '../Image'
import { Seperated } from '../Headers'

const StoryItemWrapper = styled.article`
  position: relative;
  text-align: center;
  display: grid;
  grid-template-areas: 'header' 'image' 'content';
  max-width: 100%;
  margin: auto;

  @media only screen and (min-width: 500px) {
    grid-template-areas: 'header image' 'content image';
    grid-template-columns: 6fr 4fr;
    text-align: left;
  }

  /* @media only screen and (min-width: 960px) {
    margin-left: -10%;
  } */

  ${props =>
    props.rtl &&
    css`
      @media only screen and (min-width: 500px) {
        grid-template-areas: 'image header' 'image content';
        grid-template-columns: 4fr 6fr;
      }
    `};

  ${tachyons};
`

const StoryItemHeader = styled(Seperated)`
  grid-area: header;
  margin-bottom: -20px;

  ${tachyons};
`
const StoryItemAside = styled.div`
  grid-area: image;
  display: none;
  line-height: 0;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 499px) {
    margin: 20px auto;
  }

  @media only screen and (min-width: 500px) {
    display: flex;
    width: 80%;

    ${props =>
      props.rtl
        ? css`
            margin-right: 5%;
          `
        : css`
            margin-left: 5%;
          `};
  }

  ${tachyons};
`

const StoryItemContent = styled.div`
  grid-area: content;
`

// const StorySVG = styled(Isvg)`
//   display: block;

//   svg {
//     width: 200px;
//     @media only screen and (min-width: 800px) {
//       width: 240px;
//     }
//     @media only screen and (min-width: 1200px) {
//       width: 340px;
//     }
//   }

//   ${tachyons};
// `

const StoryImage = styled(Image)``

const StoryItem = props => (
  <StoryItemWrapper {...props}>
    {props.image ? (
      <StoryItemAside rtl={props.rtl}>
        <LazyLoad height={300} offset={100} once>
          <StoryImage src={props.image} retina={props.retina} alt={props.alt} />
        </LazyLoad>
      </StoryItemAside>
    ) : null}
    <StoryItemHeader tc_on_mobile>{props.header}</StoryItemHeader>
    <StoryItemContent>{props.children}</StoryItemContent>
  </StoryItemWrapper>
)

StoryItem.defaultProps = {
  alt: '',
}

export default StoryItem
