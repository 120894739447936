import React from 'react'
import styled from 'styled-components'
import tachyons from 'styled-components-tachyons'

const CalloutBase = styled.div`
  background: rgba(255, 255, 255, ${props => props.opacity || 1});
  box-shadow: 0 24px 72px 0 rgba(0, 0, 0, 0.1);

  ${tachyons};
`

const Callout = props => (
  <CalloutBase f4 lh_copy fw6 tc ph2 pv3 dark_gray {...props}>
    {props.children}
  </CalloutBase>
)

export default Callout
