import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import FeatureBadge from '../components/FeatureBadge'
import Carousel from '../components/Carousel'
import SpeechBubble, { SpeechGroup } from '../components/SpeechBubble'
import Image from '../components/Image'
import { track as trackEvent } from '../global/analytics'
import eventConstants from '../global/eventConstants'

import { Title, A, P, Small } from '../components/Type'

import {
  Bigfish,
  Email,
  Receipts,
  Travel,
  Shipping,
  ShippingAlt,
  People,
} from '../components/Icon'

import {
  Clorox,
  DSC,
  GeneralMills,
  JohsonAndJohnson,
  KimberlyClark,
  PepsiCo,
} from '../components/Brands'

import {
  SafeData,
  SafeData2x,
  UnderstandingData,
  UnderstandingData2x,
  Wine,
  Wine2x,
  Coffee,
  Coffee2x,
  AngledSlash,
  AngledUnroll,
  Slide1,
  Slide2,
} from '../components/Images'

import { color } from '../global/constants'

import styled, { css } from 'styled-components'
import tachyons from 'styled-components-tachyons'

import Callout from '../components/Callout'
import { Seperated } from '../components/Headers'

import Section from '../components/Section'
import Wrapper from '../components/Wrapper'
import _StoryItem from '../components/StoryItem'

import { injectIntl, defineMessages } from 'gatsby-plugin-intl'

const StoryItem = styled(_StoryItem)`
  & + & {
    margin-top: 200px;
  }
  ${tachyons};
`
const Badges = styled.div`
  display: grid;
  grid-gap: 30px;

  @media only screen and (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  ${tachyons};
`

const bgScale = 0.94
const bg = {
  height: 2468 * bgScale,
  width: 2975 * bgScale,
}

const Background = styled.div`
  background-image: url(${AngledUnroll});
  background-position: -100px -290px;
  background-repeat: no-repeat;
  background-size: ${bg.width}px ${bg.height}px;
  ${tachyons};
`

const BackgroundSlash = styled.div`
  background-image: url(${AngledSlash});
  background-repeat: no-repeat;
  background-position: 0 120px;
  background-size: 100%;
`

const Slide = styled.div`
  box-sizing: border-box;

  @media screen and (max-width: 599px) {
    width: 86%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.02);
    box-shadow: 0 24px 72px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    top: -40px;
    padding: 10px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media screen and (min-width: 600px) {
    padding-top: 80px;
    padding-bottom: 30px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
  }
  ${props =>
    props.ix === 1 &&
    css`
      @media (min-width: 600px) {
        background-position-x: 14px;
        padding-left: 114px;
        padding-right: 156px;
        background-image: url(${Slide1});
      }
    `};
  ${props =>
    props.ix === 2 &&
    css`
      @media (min-width: 600px) {
        background-position-x: 10px;
        padding-left: 156px;
        padding-right: 114px;
        background-image: url(${Slide2});
      }
    `};

  ${tachyons};
`

const LogoSlide = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  img {
    transition: filter 300ms;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    max-width: 150px;

    ${props =>
      props.brand === 'DSC' &&
      css`
        max-width: 80px;
      `};
    ${props =>
      props.brand === 'PepsiCo' &&
      css`
        position: relative;
        top: -10px;
      `};
  }

  &:hover {
    img {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
  }
`

const SlideWrapper = styled(Wrapper)`
  @media (min-width: 600px) {
    position: relative;
    left: -4px;
  }

  ${tachyons};
`

const Reasons = styled.div`
  display: flex;

  @media screen and (max-width: 599px) {
    flex-direction: column;
  }

  ${tachyons};
`

const Reason = styled.div`
  box-sizing: border-box;
  position: relative;
  padding-left: 4%;
  padding-right: 4%;
  margin-bottom: 40px;
  color: #${prop => prop.color};

  &:nth-of-type(3) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 599px) {
    width: 31%;
    margin-bottom: 0;

    &:after {
      position: absolute;
      right: 0;
      top: 50%;
      height: 150px;
      margin-top: -75px;
      content: ' ';
      border-right: 1px solid ${color.grayDark};
    }

    &:nth-of-type(1) {
      padding-left: 0;
    }

    &:nth-of-type(2) {
      width: 38%;
    }

    &:nth-of-type(3) {
      padding-right: 0;

      &:after {
        border-right: 0;
      }
    }

    img {
      margin-bottom: 20px;
    }
  }

  img {
    width: 60%;
    max-width: 90px;
  }

  ${tachyons};
`

// To fix widow
const PrivacyCallout = styled(Callout)`
  @media (max-width: 599px) {
    padding-left: 44px;
    padding-right: 44px;
  }
`

export const messageDescriptors = defineMessages({
  title: {
    id: 'pages.yourData.title',
    defaultMessage: 'Your privacy is important',
  },
  heading: {
    id: 'pages.yourData.heading',
    defaultMessage: 'Your privacy is important. Period.',
  },
  subHeading: {
    id: 'pages.yourData.subHeading',
    defaultMessage:
      'We want to be as clear as possible about what data we collect, how we use it, and what we do to protect it.',
  },
  section1Title: {
    id: 'pages.yourData.section1Title',
    defaultMessage: 'How we keep your data safe',
  },
  section1Content: {
    id: 'pages.yourData.section1Content',
    defaultMessage: `We have established security protocols for our services and
                    practices. As part of these protocols, we encrypt sensitive
                    data, including anything that could identify you. We
                    regularly monitor, scan, and test for vulnerabilities in our
                    production systems. Access to our databases is limited, and
                    employees who have access must complete ongoing privacy and
                    security training.`,
  },

  section2Title: {
    id: 'pages.yourData.section2Title',
    defaultMessage: 'What kind of data we collect',
  },
  section2Content: {
    id: 'pages.yourData.section2Content',
    defaultMessage: `When you use Unroll.Me, we collect some basic contact
                    information to get your account set up. We also set up
                    connections with the email accounts you authorize us to
                    access so that we can make Unroll.Me work. These connections
                    allow us to collect information from your transactional
                    emails, like:`,
  },

  badge1: {
    id: 'pages.yourData.badge1',
    defaultMessage: 'Email subscriptions',
  },
  badge2: {
    id: 'pages.yourData.badge2',
    defaultMessage: 'Purchase receipts',
  },
  badge3: {
    id: 'pages.yourData.badge3',
    defaultMessage: 'Travel reservations & booking-related emails',
  },
  badge4: {
    id: 'pages.yourData.badge4',
    defaultMessage: 'Shipping confirmations & online shipping returns',
  },
  privacyCallout: {
    id: 'pages.yourData.privacyCallout',
    defaultMessage: `We are not interested in your personal emails. Our algorithms
                and processes are designed to focus on commercial and
                transactional emails.`,
  },
  section3Title: {
    id: 'pages.yourData.section3Title',
    defaultMessage: 'How we use your data',
  },
  section3Content1: {
    id: 'pages.yourData.section3Content1',
    defaultMessage: 'Unroll.Me is owned by',
  },
  section3Content2: {
    id: 'pages.yourData.section3Content2',
    defaultMessage: 'NielsenIQ',
  },
  section3Content3: {
    id: 'pages.yourData.section3Content3',
    defaultMessage: `, an e-commerce measurement business that provides companies
                  with insights into industry trends, corporate performance, and
                  the competitive landscape. Unless otherwise`,
  },
  section3Content4: {
    id: 'pages.yourData.section3Content4',
    defaultMessage: 'restricted by your email provider',
  },
  section3Content5: {
    id: 'pages.yourData.section3Content5',
    defaultMessage: `, when you sign up for Unroll.Me, we share your transactional
                  emails with NielsenIQ, who helps us de-identify and
                  combine your information with that of millions of users,
                  including NielsenIQ’s shopping panel. Once that
                  information has been de-identified and processed, we are able
                  to do a ton of cool stuff. Here are a few examples:`,
  },

  slide1Title: {
    id: 'pages.yourData.slide1Title',
    defaultMessage: 'We tell data stories',
  },
  slide1Section1: {
    id: 'pages.yourData.slide1Section1',
    defaultMessage: `Your data, together with the data of millions of other
                      panelists, helps us discover interesting things about
                      what’s trending in e-commerce, like which demographic is
                      buying the most fidget spinners in the U.S. (It’s not who
                      you’d expect). We also use this data to tell stories like:`,
  },
  slide1Comment1: {
    id: 'pages.yourData.slide1Comment1',
    defaultMessage:
      'Which company is dominating the food delivery market? (hint: they are “Uber” good at it)',
  },
  slide1Comment2: {
    id: 'pages.yourData.slide1Comment2',
    defaultMessage:
      'How much online alcohol delivery grew in 2017 (did you guess 32.7?)',
  },
  slide1Comment3: {
    id: 'pages.yourData.slide1Comment3',
    defaultMessage:
      'How much Fenty Beauty buyers online? (five times more than the average online cosmetics buyer!)',
  },
  slide1Section2: {
    id: 'pages.yourData.slide1Section2',
    defaultMessage: `As part of our business, we share these insights through
                      reports that we create and datasets that we provide.
                      (We’re big data nerds, in case you haven’t noticed.)`,
  },
  slide2Title: {
    id: 'pages.yourData.slide2Title',
    defaultMessage: 'We create market research products',
  },
  slide2Section1: {
    id: 'pages.yourData.slide2Section1',
    defaultMessage: `Our market research products help companies better
                      understand who their customers are, what their competition
                      is doing, how to better operate their business, what’s
                      trending, and what investments to make. Our research
                      products do not include personally identifiable
                      information. Through NielsenIQ data, our
                      clients can answer questions like:`,
  },
  slide2Comment1: {
    id: 'pages.yourData.slide2Comment1',
    defaultMessage:
      'What is Amazon’s market share for online home goods - and how far behind are other online retailers?',
  },
  slide2Comment2: {
    id: 'pages.yourData.slide2Comment2',
    defaultMessage:
      'Which online luggage retailer grew nearly 200 percent from 2017 to 2018?',
  },
  slide2Comment3: {
    id: 'pages.yourData.slide2Comment3',
    defaultMessage:
      'If someone adds beer to their order, do they buy more additional groceries?',
  },
  slide2Section2P1: {
    id: 'pages.yourData.slide2Section2P1',
    defaultMessage:
      'To learn more, check out NielsenIQ’s full-suite of',
  },
  slide2Section2P2: {
    id: 'pages.yourData.slide2Section2P2',
    defaultMessage: 'market research products.',
  },
  section4Title: {
    id: 'pages.yourData.section4Title',
    defaultMessage: 'Who we share information with',
  },
  section4Content: {
    id: 'pages.yourData.section4Content',
    defaultMessage: `We sell our measurement products and datasets to companies
                  that want to learn more about their business, their markets,
                  or their competitors. We also sell our products to other
                  businesses that want to learn about company or market trends
                  for investments, research or other business purposes. Our
                  customers are Fortune 500 companies, household brands,
                  investment companies, and other businesses that want to answer
                  questions like:`,
  },
  section4Reason1: {
    id: 'pages.yourData.section4Reason1',
    defaultMessage: 'Does free shipping work?',
  },
  section4Reason2: {
    id: 'pages.yourData.section4Reason2',
    defaultMessage: 'What types of people are likely to buy my products?',
  },
  section4Reason3: {
    id: 'pages.yourData.section4Reason3',
    defaultMessage: 'Who are the rising stars in my industry vertical?',
  },

  ourCustomersTitle: {
    id: 'pages.yourData.ourCustomersTitle',
    defaultMessage: 'A few of our current and former customers include:',
  },
  ourCustomersContentP1: {
    id: 'pages.yourData.ourCustomersContentP1',
    defaultMessage: `We also share information with our parent company, service
                  providers, and business partners. But, as we said earlier, we
                  are not interested in your personal emails, and our systems
                  are not designed to use or share that information. Want to
                  learn more? Check out our`,
  },
  ourCustomersContentP2: {
    id: 'pages.yourData.ourCustomersContentP2',
    defaultMessage: 'Privacy Policy',
  },
  ourCustomersContentP3: {
    id: 'pages.yourData.ourCustomersContentP3',
    defaultMessage: 'Terms of Service pages.',
  },

  section5Title: {
    id: 'pages.yourData.section5Title',
    defaultMessage: 'Our commitment to transparency',
  },
  section5Content1: {
    id: 'pages.yourData.section5Content1',
    defaultMessage: `It is our responsibility to let you know what data we collect
                  and how we use it. And we don’t take this responsibility
                  lightly. We have a dedicated Chief Privacy Officer, and an
                  internal task force whose primary focus is improving and
                  communicating our privacy practices, so we can be transparent
                  with you.`,
  },
  section5Content2: {
    id: 'pages.yourData.section5Content2',
    defaultMessage:
      'If you have any questions, concerns, or want anything further explained, please reach out to us:',
  },
  and: {
    id: 'pages.yourData.and',
    defaultMessage: 'and',
  },
})

const track = (eventName, eventProps) => {
  trackEvent(eventName, {
    ...eventProps,
    Page: eventConstants.yourData.pageName,
  })
}

class Privacy extends Component {
  componentDidMount() {
    track(eventConstants.yourData.pageLoad)
  }

  render() {
    const { intl } = this.props
    const messages = Object.keys(messageDescriptors).reduce((messages, key) => {
      messages[key] = intl.formatMessage(messageDescriptors[key])
      return messages
    }, {})
    return (
      <Layout title={messages.title}>
        <div>
          <Background>
            <Wrapper small>
              <Hero tc mv4 pv4>
                <Section large>
                  <Title>{messages.heading}</Title>
                  <P f4 mt2 gray>
                    {messages.subHeading}
                  </P>
                </Section>
              </Hero>
            </Wrapper>
            <Wrapper medium pb2>
              <Section mt4>
                <StoryItem
                  header={<Title>{messages.section1Title}</Title>}
                  image={SafeData}
                  retina={SafeData2x}
                  alt="How we keep your data safe"
                  rtl
                >
                  <P f4>{messages.section1Content}</P>
                </StoryItem>

                <StoryItem
                  header={<Title>{messages.section2Title}</Title>}
                  image={UnderstandingData}
                  retina={UnderstandingData2x}
                  alt="What kind of data we collect"
                >
                  <P f4>{messages.section2Content}</P>

                  <Badges mt3>
                    <FeatureBadge color="#EB8B2D" image="small">
                      <img src={Email} alt="Email subscriptions" />
                      <span>{messages.badge1}</span>
                    </FeatureBadge>
                    <FeatureBadge color="#32AEC0" image="small">
                      <img src={Receipts} alt="Purchase receipts" />
                      <span>{messages.badge2}</span>
                    </FeatureBadge>
                    <FeatureBadge color="#EE6B50" image="small">
                      <img
                        src={Travel}
                        alt="Travel reservations & booking-related emails"
                      />
                      <span>{messages.badge3}</span>
                    </FeatureBadge>
                    <FeatureBadge color="#417505" image="small">
                      <img
                        src={Shipping}
                        alt="Shipping confirmations & online shipping returns"
                      />
                      <span>{messages.badge4}</span>
                    </FeatureBadge>
                  </Badges>
                </StoryItem>
              </Section>
            </Wrapper>
            <Wrapper medium mv4>
              <PrivacyCallout opacity="0.8" mv4>
                {messages.privacyCallout}
              </PrivacyCallout>
            </Wrapper>
            <Wrapper small mv4>
              <Section mt4>
                <Seperated tc>
                  <Title tc>{messages.section3Title}</Title>
                </Seperated>
                <P f4 tc>
                  {messages.section3Content1}{' '}
                  <A
                    href="https://nielseniq.com/global/en/landing-page/rakuten-intelligence-joins-nielseniq/"
                    target="_blank"
                    onClick={() => {
                      track(eventConstants.yourData.riLinkClicked)
                    }}
                  >
                    {messageDescriptors.section3Content2.defaultMessage}
                  </A>
                  {messages.section3Content3}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/legal/privacy/#restrictions"
                  >
                    {' '}
                    {messages.section3Content4}
                  </a>
                  {messageDescriptors.section3Content5.defaultMessage}
                </P>
              </Section>
            </Wrapper>
            <SlideWrapper slide>
              <Carousel
                slides={[
                  <Slide ix={1}>
                    <Title f3 gray mv3 lh_title>
                      {messages.slide1Title}
                    </Title>
                    <LazyLoad height={420} offset={100} once>
                      <Image
                        src={Wine}
                        retina={Wine2x}
                        alt="Graph of how online wine is growing more seasonal"
                      />
                    </LazyLoad>
                    <P f5>{messages.slide1Section1}</P>
                    <SpeechGroup mv3>
                      <div>
                        <SpeechBubble color="EB8B2D" ix={1} mt2>
                          {messages.slide1Comment1}
                        </SpeechBubble>
                      </div>
                      <div>
                        <SpeechBubble
                          color="00aabb"
                          ix={2}
                          right
                          point_right
                          mt4
                        >
                          {messages.slide1Comment2}
                        </SpeechBubble>
                      </div>
                      <div>
                        <SpeechBubble ix={3} color="EE6B50" left>
                          {messages.slide1Comment3}
                        </SpeechBubble>
                      </div>
                    </SpeechGroup>
                    <P f5>
                      {messages.slide1Section2}
                    </P>
                  </Slide>,
                  <Slide ix={2}>
                    <Title f3 gray mv3 lh_title>
                      {messages.slide2Title}
                    </Title>
                    <LazyLoad height={351} offset={100} once>
                      <Image
                        src={Coffee}
                        retina={Coffee2x}
                        alt="Graph showing coffee consumption by age"
                      />
                    </LazyLoad>
                    <P f5>{messageDescriptors.slide2Section1.defaultMessage}</P>
                    <SpeechGroup mv3>
                      <div>
                        <SpeechBubble color="EB8B2D" mt2>
                          {messages.slide2Comment1}
                        </SpeechBubble>
                      </div>
                      <div>
                        <SpeechBubble color="00aabb" right point_right mt4>
                          {messages.slide2Comment2}
                        </SpeechBubble>
                      </div>
                      <div>
                        <SpeechBubble color="EE6B50" left>
                          {messages.slide2Comment3}
                        </SpeechBubble>
                      </div>
                    </SpeechGroup>
                    <P f5>
                      {messageDescriptors.slide2Section2P1.defaultMessage}{' '}
                      <A href="https://nielseniq.com/global/en/landing-page/rakuten-intelligence-joins-nielseniq/">
                        {messages.slide2Section2P2}
                      </A>
                    </P>
                  </Slide>,
                ]}
              />
            </SlideWrapper>
          </Background>
          <BackgroundSlash>
            <Wrapper medium pt4>
              {' '}
              <article>
                <Seperated tc mt4>
                  <Title tc>{messages.section4Title}</Title>
                </Seperated>
                <P f4 tc>
                  {messages.section4Content}
                </P>
                <Reasons mv4>
                  <Reason color="EB8B2D" f3 lh_title fw6 tc>
                    <img src={ShippingAlt} alt="Does free shipping work?" />
                    <div>{messages.section4Reason1}</div>
                  </Reason>
                  <Reason color="32AEC0" f3 lh_title fw6 tc>
                    <img
                      src={People}
                      alt="What types of people are likely to buy my products?"
                    />
                    <div>{messages.section4Reason2}</div>
                  </Reason>
                  <Reason color="EE6B50" f3 lh_title fw6 tc>
                    <img
                      src={Bigfish}
                      alt="Who are the rising stars in my industry vertical?"
                    />
                    <div>{messages.section4Reason3}</div>
                  </Reason>
                </Reasons>
              </article>
            </Wrapper>
            <Wrapper slide>
              <Callout mb4>
                <Title f4 gray>
                  {messages.ourCustomersTitle}
                </Title>

                <Carousel
                  slides={[
                    <LogoSlide brand="GeneralMills">
                      <img src={GeneralMills} alt="General Mills logo" />
                    </LogoSlide>,
                    <LogoSlide brand="Clorox">
                      <img src={Clorox} alt="Clorox logo" />
                    </LogoSlide>,
                    <LogoSlide brand="JohsonAndJohnson">
                      <img src={JohsonAndJohnson} alt="Johson & Johnson logo" />
                    </LogoSlide>,
                    <LogoSlide brand="DSC">
                      <img src={DSC} alt="DSC logo" />
                    </LogoSlide>,
                    <LogoSlide brand="KimberlyClark">
                      <img src={KimberlyClark} alt="Kimberly Clark logo" />
                    </LogoSlide>,
                    <LogoSlide brand="PepsiCo">
                      <img src={PepsiCo} alt="PepsiCo logo" />
                    </LogoSlide>,
                  ]}
                  perPage={4}
                  mobilePerPage={2}
                />

                <Small ph3>
                  {messages.ourCustomersContentP1}{' '}
                  <Link to="/legal/privacy">
                    {messages.ourCustomersContentP2}
                  </Link>{' '}
                  {messages.and}{' '}
                  <Link to="/legal/terms">
                    {messages.ourCustomersContentP3}
                  </Link>
                </Small>
              </Callout>
              <Section mv4>
                <Seperated tc mt4>
                  <Title tc>{messages.section5Title}</Title>
                </Seperated>
                <P f4 tc>
                  {messages.section5Content1}
                </P>

                <P f4 tc>
                  {messages.section5Content2}{' '}
                  <A
                    href="mailto:support@unroll.me"
                    onClick={() => {
                      track(eventConstants.common.supportLinkClicked, {
                        [eventConstants.common.supportType.propName]:
                          eventConstants.common.supportType.email,
                        [eventConstants.common.supportCta.propName]:
                          eventConstants.common.supportCta.emailSupport,
                      })
                    }}
                  >
                    support@unroll.me
                  </A>
                  .
                </P>
              </Section>
            </Wrapper>
          </BackgroundSlash>
        </div>
      </Layout>
    )
  }
}

export default injectIntl(Privacy)
