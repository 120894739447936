import React from 'react'
import styled, { css } from 'styled-components'
import tachyons from 'styled-components-tachyons'

const SpeechGroup = styled.div`
  display: grid;
  grid-gap: 20px;

  @media (max-width: 599px) {
    //
  }

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  ${tachyons};
`

const Bubble = styled.div`
  display: inline-block;
  position: relative;
  border-radius: 20px;
  padding: 17px 20px;
  margin-bottom: 1.5em;
  font-size: 14px;
  line-height: 1.5;
  color: white;
  text-align: left;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);

  ${props =>
    props.color &&
    css`
      background: #${props.color};
    `};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 18px solid transparent;
    border-bottom: 0;
    margin-left: -9px;
    margin-bottom: -18px;

    ${props =>
      props.color &&
      css`
        border-top-color: #${props.color};
      `};

    ${props =>
      props.point_right
        ? css`
            border-right: 0;
          `
        : css`
            border-left: 0;
          `};
    ${props =>
      props.right &&
      css`
        left: 75%;
      `};
    ${props =>
      props.left &&
      css`
        left: 25%;
      `};
  }

  @media (max-width: 599px) {
    width: 68%;
    position: relative;

    ${props =>
      props.ix === 1 &&
      css`
        left: -12.5%;
      `};

    ${props =>
      props.ix === 2 &&
      css`
        right: -12.5%;
      `};
  }

  @media (min-width: 600px) {
    ${tachyons};
  }
`

const SpeechBubble = props => <Bubble {...props}>{props.children}</Bubble>

export { SpeechGroup }
export default SpeechBubble
